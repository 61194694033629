* {
  box-sizing: border-box;
  font-family: "IBM Plex Sans", sans-serif;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.spy-nav > p,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small
  .ant-descriptions-item-content,
.ant-table-tbody > tr > td,
.ant-input,
.ant-picker-input > input,
.ant-radio-wrapper,
.ant-select-item,
.ant-menu-horizontal > .ant-menu-item a,
.ant-select-selection-item,
.ant-menu-submenu-title,
.ant-menu-item a {
  font-weight: 500;
  /* color: #000; */
}
.ant-table-small .ant-table-thead > tr > th,
.ant-table-thead > tr > th {
  background: #f0f3f5;
  font-weight: 500;
  color: #000;
}
.ant-btn,
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-weight: 500;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #c8ced3;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #c8ced3;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #f0f3f5;
  /* border-bottom: 1px solid #c8ced3; */
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: 1px solid #c8ced3;
}
.ant-table-filter-trigger {
  color: #898989;
}
html,
body {
  min-height: 100%;
  height: initial;
}
body {
  background-color: #e4e5e6;
  position: relative;
}
svg {
  vertical-align: baseline;
}
.vertical-baseline svg {
  vertical-align: baseline;
}
.vertical-bottom svg {
  vertical-align: bottom;
}

.cursor-pointer {
  cursor: pointer;
}
/* login */
.login-container {
  min-height: 100vh;
  background-color: #fff;
}
.background-login {
  padding: 0 5%;
}
.div-login {
  padding: 70px 0 0 0;
}

.ant-input::placeholder,
.ant-select-selection-placeholder,
.ant-picker-input > input::placeholder {
  color: #959595;
  opacity: 1;
}

.input-login {
  width: 100%;
  /* color: #fff !important; */
  border: none;
  border-bottom: 1px solid rgb(0, 112, 188);
  background-color: #fff !important;
  margin-top: 20px;
  padding: 4px 11px;
  border-radius: 0;
}

.login-container input:focus {
  border-bottom: 2px solid rgb(0, 112, 188);
  outline: none;
  box-shadow: none !important;
}
input#password::placeholder {
  opacity: 0.6;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-login {
  width: 100%;
  margin-top: 50px;
  padding: 2px;
  background-color: rgb(0, 112, 188);
  border-radius: 10px;
  color: #fff;
}
.form-item-username {
  background-color: rgb(0, 112, 188);
  border-bottom: rgb(232, 240, 254) 0.5px solid;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.form-login-icon {
  padding: 20px;
  background-color: #fff;
}
.form-item-username .form-login-icon {
  border-bottom: rgb(0, 112, 188) 1px solid;
  border-top-left-radius: 8px;
}
.form-item-password .form-login-icon {
  border-top: rgb(0, 112, 188) 1px solid;
  border-bottom-left-radius: 8px;
}
.form-login-icon i {
  color: #1c5397 !important;
  font-size: 30px;
}
.form-login-input {
  width: 80%;
  height: 100%;
  padding: 5px 0 0 10px;
}
.form-login-input p {
  margin: 0;
  font-weight: 500;
}
.form-info {
  width: 70%;
  margin: auto !important;
}
.ant-form-item {
  /* margin-bottom: 0 !important; */
}
.image-login {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.branch {
  font-size: 24px;
  font-weight: 500;
}
/* login */
.user-login {
  color: #fff;
}

.page-layout {
  /* padding-top: 15px; */
  padding-bottom: 60px;
}

.loading-component {
  text-align: center;
  padding: 40px 0;
}

.avatar {
  width: 180px;
}

.action-wrapper {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 10px 0;
  box-shadow: 0 -2px 5px 0px rgba(126, 126, 126, 0.4);
  z-index: 1000;
}
.spy-nav {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
}
.spy-nav-route.is-current {
  font-weight: 700;
}
.member-info-wrapper.activeRoute {
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.3);
}

.member-avatar {
  cursor: pointer;
}
.member-avatar:hover {
  filter: brightness(0.7);
}
.thumb {
  background-color: #fff;
  position: relative;
}
.thumb img {
  height: 200px !important;
  object-fit: cover;
  /* border: 1px solid #f1f1f1; */
}
.thumb-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
}
.btn-remove {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0px 6px;
  cursor: pointer;
  z-index: 3;
}
.btn-remove:hover {
  background-color: #000;
}
.thumb:hover .btn-remove,
.thumb:hover .thumb-overlay {
  display: block;
}
.toggle-filter {
  cursor: pointer;
  -webkit-user-select: none;
  transition: all 300ms ease;
}
.toggle-filter:hover {
  outline: 1px solid #1c5397;
}
.toggle-filter.revert {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.hide-filter {
}

.entry-box {
  transition: box-shadow 500ms ease;
}
.entry-thumb {
  overflow: hidden;
}
.entry-thumb img {
  transition: all 300ms ease;
}
.entry-box:hover img {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
}

.entry-wrapper.selected .entry-box {
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.4);
}

.ant-table-tbody > tr.ant-table-row.selected > td {
  background-color: #b3e5fc !important;
}

/* custom ant design select */
.ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
  /* max-width: 250px; */
  min-width: 150px;
}
.ant-form-item .w-100.ant-select,
.ant-form-item .w-100.ant-cascader-picker {
  max-width: initial;
}

.ant-skeleton {
  margin-top: 10px;
  margin-bottom: 10px;
}
/* custom ant design select */

/* custom ant design description */
.ant-descriptions-title {
  font-weight: 500;
}
/* custom ant design description */

.clear-all-filter {
  color: #1c5397;
  cursor: pointer;
}

#promotion-detail .ant-form-item-label,
#member-status .ant-form-item-label,
#formChangeScoreMember .ant-form-item-label,
#bankName .ant-form-item-label,
#addBranch .ant-form-item-label {
  text-align: left;
}
#card-message .ant-card-extra {
  padding: 10px 0 !important;
}
.btn-circle {
  position: fixed;
  right: 16px;
  bottom: 70px;
  z-index: 10;
}

.ant-form-item-explain,
.ant-form-item-extra {
  position: absolute;
  top: 37px;
}
.go-back:hover {
  color: #1c5397;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0 10px;
}
.ant-form-item-explain {
  position: initial;
}

.list-upload .ant-upload.ant-upload-select-picture-card {
  width: 150px;
  height: 150px;
}
.banner-upload .ant-upload.ant-upload-select-picture-card {
  width: 300px;
  height: 300px;
  margin-right: 0;
}

/* .upload-component-wrapper .avatar-uploader .ant-upload{
  pointer-events: none;
} */

.thumb-upload-wrapper .upload-action-wrapper span {
  pointer-events: initial;
  cursor: pointer !important;
}

.banner-upload .addition-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.list-upload .ant-upload-list-picture-card-container {
  width: 150px;
  height: 150px;
}

.banner-upload .ant-upload-select-text {
  background-color: #fafafa;
  width: 350px;
  min-height: 200px;
  border: 1px dashed #d9d9d9;
  position: relative;
}

.ant-upload-select-text:not(.ant-upload-disabled):hover {
  border: 1px dashed #4750db;
}

.fit-body.banner-upload .ant-upload-select-text {
  width: 100% !important;
  min-height: 116px;
}
/* .upload-component-wrapper{
  width: 350px;
  min-height: 200px;
  position: relative;
} */
.event-detail-container .upload-component-wrapper {
  width: 100%;
}

.upload-action-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none !important;
  z-index: 1000;
}
.upload-component-wrapper:hover .upload-action-wrapper {
  display: inline-flex !important;
}

.ant-message {
  z-index: 1500 !important;
}

.noti-create-container .ant-descriptions-bordered .ant-descriptions-item-label {
  width: 250px;
}

.status-btn:hover {
  filter: brightness(1.16);
  -webkit-filter: brightness(1.16);
}

.status-btn:disabled {
  filter: brightness(1);
  -webkit-filter: brightness(1);
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.document-editor__editable-container {
  /* max-height: 10cm; */
}
.document-editor__editable-container .customEditor .ck-editor__editable {
  min-height: 8cm;
}
.document-editor__editable-container
  .customEditor
  figcaption.ck-editor__editable {
  min-height: 50px;
}

.noti-create-container .customEditor {
  height: 350px !important;
}
/* .document-editor__editable-container .customEditor1 .ck-editor__editable {
  height: 10px;
}
.document-editor__editable-container
  .customEditor1
  figcaption.ck-editor__editable {
  height: 50px;
} */

.stat-member-row .apexcharts-canvas {
  margin: auto;
}
.example {
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
.header-noti-wrapper svg {
  vertical-align: sub;
}
.header-noti-wrapper .ant-popover {
  width: 300px !important;
}

/* Header notify */
.header-noti-wrapper .ant-popover-inner-content {
  padding: 0;
}
.ant-list.notify-list {
  width: 250 !important;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.seen {
  /* background-color: #edf2fa; */
  background-color: #eeeeee;
}
.ant-list-split .ant-list-item.seen {
  border-bottom: 1px solid #e4e4e4;
}
.action-noti-wrapper {
  border-bottom: 1px solid #e4e4e4;
}
.load-more-wrapper {
  border-top: 1px solid #e4e4e4;
}
/* Header notify */

.cursor-point {
  cursor: pointer;
}
.disable-option {
  color: #d8d8d8;
}

.notify-list .ant-list-item:hover {
  background-color: #f2f2f2;
  border-bottom: 1px solid #e4e4e4;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.noti-create-container .ant-descriptions-view table {
  table-layout: auto;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
